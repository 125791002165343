import React from "react";
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import { Button, Col, Container, Modal, Row } from "react-bootstrap"
import preferencesIcon from "../../images/preferences-icon.svg"

import {
  launch,
  onReady,
  fetchTags,
  addTags,
  removeTag,
} from 'notificare-web/core';
import {
  onNotificationOpened,
  onNotificationActionOpened,
  enableRemoteNotifications,
  hasWebPushCapabilities,
} from 'notificare-web/push';
import { presentNotification, presentAction } from 'notificare-web/push-ui';
import {
  onMessagePresented,
  onMessageFinishedPresenting,
  onMessageFailedToPresent,
  onActionExecuted,
  onActionFailedToExecute,
} from 'notificare-web/in-app-messaging';

class NotificarePushManager extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      application: null,
      showBanner: false,
      showTagSelector: false,
      tag_products: true,
      tag_blog: true,
      tag_news: true
    }
  }

  componentDidMount(){
    document.documentElement.setAttribute('data-notificare-theme', 'light');
    this.initNotificare();
  }

  initNotificare = async () => {
    onReady(async (application) => {
      this.setState({
        application: application
      })
      let shouldShow = false,
        lastTime = window.localStorage.getItem('onboardingLastAttempt'),
        interval = (60 * 60 * 1000) * 24;

      if (!lastTime || (lastTime && parseInt(lastTime) < (Date.now() - interval))) {
        shouldShow = true;
      }

      if (shouldShow && this.fetchPermission() === 'default' && hasWebPushCapabilities()) {
        this.setState({
          showBanner: true
        });
        this.addClassToBody();
      }

      let tags = await fetchTags();
      if (!tags.includes('tag_products')) {
        this.setState({
          tag_products: false
        });
      }

      if (!tags.includes('tag_blog')) {
        this.setState({
          tag_blog: false
        });
      }

      if (!tags.includes('tag_news')) {
        this.setState({
          tag_news: false
        });
      }

      if (this.fetchPermission() === 'granted') {
        await addTags(['tag_webpush_enabled']);
        await removeTag('tag_webpush_disabled');

        // No need to do this now coz the icon is visible every time
        // let shouldShowReminder = false,
        //   lastTimeReminder = window.localStorage.getItem('reminderTags'),
        //   intervalReminder = (60 * 60 * 1000) * (24 * 30); // every 30 days
        //
        // if (!lastTimeReminder || (lastTimeReminder && parseInt(lastTimeReminder) < (Date.now() - intervalReminder))) {
        //   shouldShowReminder = true;
        // }
        //
        // if (shouldShowReminder) {
        //   this.setState({
        //     showTagSelector: true
        //   });
        // }

      } else {
        await addTags(['tag_webpush_disabled']);
        await removeTag('tag_webpush_enabled');
      }

    });

    onNotificationOpened((notification) => {
      presentNotification(notification);
    });

    onNotificationActionOpened((notification, action) => {
      presentAction(notification, action);
    });

    onMessagePresented((message) => {
      //console.log(message);
    });

    onMessageFinishedPresenting((message) => {
      //console.log(message);
    });

    onMessageFailedToPresent((message) => {
      //console.log(message);
    });

    onActionExecuted((message) => {
      //console.log(message);
    });

    onActionFailedToExecute((message) => {
      //console.log(message);
    });

    await launch();
  }

  fetchPermission = () => {
    let permission = 'default';

    if (this.state.application) {
      let application = this.state.application;
      if (navigator &&
        window &&
        'serviceWorker' in navigator &&
        'showNotification' in ServiceWorkerRegistration.prototype &&
        'PushManager' in window &&
        "Notification" in window &&
        Notification.permission
      ) {
        permission = Notification.permission;
      } else {
        if (window &&
          'safari' in window &&
          'pushNotification' in window.safari &&
          application &&
          application.websitePushConfig.info &&
          application.websitePushConfig.info.subject &&
          application.websitePushConfig.info.subject.UID
        ) {
          let status = window.safari.pushNotification.permission(application.websitePushConfig.info.subject.UID);
          permission = status.permission;
        }
      }
    }
    return permission;
  }

  handleAccept = async () => {
    window.localStorage.removeItem('onboardingLastAttempt');
    this.setState({
      showBanner: false
    });
    this.removeClassToBody();
    window.localStorage.setItem('firstTimeUser', 1);
    await enableRemoteNotifications();
    if (this.fetchPermission() === 'granted') {
      if (parseInt(window.localStorage.getItem('firstTimeUser'))) {
        this.setState({
          showTagSelector: true
        });
        await addTags(['tag_webpush_enabled']);
        await removeTag('tag_webpush_disabled');
      }
    }
  }

  handleDeny = () => {
    localStorage.setItem('onboardingLastAttempt', Date.now());
    this.setState({
      showBanner: false
    });
    this.removeClassToBody();
  }

  addClassToBody = () => {
    document.body.classList.add("has-notificare-banner");
  }

  removeClassToBody = () => {
    document.body.classList.remove("has-notificare-banner");
  }

  handleClose = () => {
    this.setState({
      showTagSelector: false
    });
    this.handleTags();
  }

  handleTags = async () => {

    if (this.state.tag_products) {
      await addTags(['tag_products']);
    } else {
      await removeTag('tag_products');
    }

    if (this.state.tag_blog) {
      await addTags(['tag_blog']);
    } else {
      await removeTag('tag_blog');
    }

    if (this.state.tag_news) {
      await addTags(['tag_news']);
    } else {
      await removeTag('tag_news');
    }

    this.setState({
      showTagSelector: false
    });

    window.localStorage.setItem('firstTimeUser', 0);
    window.localStorage.setItem('reminderTags', Date.now());
  }


  render() {

    if (this.state.showBanner) {
      if (!this.props.scrollClass) {
        return (
          <div className={`notificare-push-banner`}>
            <Container>
              <div className={`bg`}></div>
              <div className={`wrapper`}>
                <div className={`title`}><FormattedMessage id="notificare.title" /></div>
                <div className={`text`}><FormattedMessage id="notificare.text" /></div>
                <div className={`buttons`}>
                  <Button className={`button`} variant={`outline-light`} onClick={this.handleAccept}><FormattedMessage id="notificare.accept" /></Button>
                  <Button className={`button deny`} variant={`transparent`} onClick={this.handleDeny}><FormattedMessage id="notificare.deny" /></Button>
                </div>
              </div>
            </Container>
          </div>
        )
      } else {
        return null;
      }
    } else {
      if (this.state.showTagSelector) {
        return (
          <Modal show={this.state.showTagSelector} centered={true} backdrop={`static`} dialogClassName={`tag-selector`} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><FormattedMessage id="notificare.tagSelector.title" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <p className={`text-center p-1`}><FormattedMessage id="notificare.tagSelector.text" /></p>

              <div className={`form-area`}>
                <Row>
                  <Col md={12}>
                    <div className={`form-field-group mt-3`}>
                      <label className="checkbox-container">
                        <span className={`title`}>{this.props.intl.formatMessage({id: "notificare.tagSelector.topics.products.title"})}</span>
                        {this.props.intl.formatMessage({id: "notificare.tagSelector.topics.products.text"})}
                        <input type={`checkbox`} checked={this.state.tag_products} name={`products`} onChange={(e) => {
                          this.setState({
                            tag_products: e.target.checked
                          });
                        }} />
                        <span className={`checkmark`}></span>
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className={`form-field-group mt-3`}>
                      <label className="checkbox-container">
                        <span className={`title`}>{this.props.intl.formatMessage({id: "notificare.tagSelector.topics.blog.title"})}</span>
                        {this.props.intl.formatMessage({id: "notificare.tagSelector.topics.blog.text"})}
                        <input type={`checkbox`} checked={this.state.tag_blog} name={`blog`} onChange={(e) => {
                          this.setState({
                            tag_blog: e.target.checked
                          });
                        }} />
                        <span className={`checkmark`}></span>
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className={`form-field-group mt-3`}>
                      <label className="checkbox-container">
                        <span className={`title`}>{this.props.intl.formatMessage({id: "notificare.tagSelector.topics.news.title"})}</span>
                        {this.props.intl.formatMessage({id: "notificare.tagSelector.topics.news.text"})}
                        <input type={`checkbox`} checked={this.state.tag_news} name={`news`} onChange={(e) => {
                          this.setState({
                            tag_news: e.target.checked
                          });
                        }} />
                        <span className={`checkmark`}></span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.handleTags}><FormattedMessage id="notificare.tagSelector.button" /></Button>
            </Modal.Footer>
          </Modal>
        )
      } else {
        if (this.fetchPermission() === 'granted') {
          return (
            <button type={`button`} className={`push-settings`} onClick={() => {
              this.setState({
                showTagSelector: true
              });
            }}>
              <div className={`settings-icon`}>
                <img src={preferencesIcon} alt={``} />
              </div>
            </button>
          );
        } else {
          return null;
        }
      }
    }

  }
}

export default injectIntl(NotificarePushManager);
