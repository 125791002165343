import React from "react"
import {Container} from 'react-bootstrap'
import {injectIntl, Link} from "gatsby-plugin-react-intl"
import logo from "../images/notificare-actito-logo.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderData from "../content/header.json"
import ImageFluid from "./misc/image-fluid";
import NotificarePushManager from "./misc/notificare"

function Header({ intl, siteTitle, scrollClass, theme }) {

    const [ headerClass, setHeaderClass ] = React.useState("");

    const onShowSubmenu = (e) => {
        e.persist();
        if (e.target.nextElementSibling &&
            e.target.nextElementSibling.classList &&
            e.target.nextElementSibling.classList.length &&
            e.target.nextElementSibling.classList.contains('submenu')) {

            //Close all open
            let allMenus = document.getElementsByClassName("submenu");
            for (let i = 0; i < allMenus.length; i++) {
                allMenus[i].classList.remove('open');
            }
            //Open the new one
            let submenu = e.target.nextElementSibling;
            submenu.classList.add('open');
            // also close when click in document
            submenu.addEventListener("mouseleave", onHideSubMenu);

            // also close when click in document
            document.addEventListener("mouseup", closeAll);

        }
    }

    const onHideSubMenu = (e) => {
        if (e.target) {
            e.target.classList.remove('open');
        }
    }

    const onHideAllSubMenus = (e) => {
        //Close all open
        closeAll();
    }

    const closeAll = () => {
        //Close all open
        let allMenus = document.getElementsByClassName("submenu");
        for (let i = 0; i < allMenus.length; i++) {
            allMenus[i].classList.remove('open');
        }
    }

    const handleOpenMenu = (e) => {
        e.preventDefault();
        setHeaderClass("is-open");
    }

    const handleCloseMenu = (e) => {
        e.preventDefault();
        setHeaderClass("");
    }

    const handleThemeForDemoButton = (theme) => {
        if (theme === "secondary") {
            return 'btn btn-lg btn-black demo-button';
        } else if (theme === "black") {
            return 'btn btn-lg btn-white demo-button';
        } else {
            return 'btn btn-lg btn-secondary demo-button';
        }
    }

    return(
        <div className={`header ` + theme + ` ` + scrollClass + ` ` + headerClass}>
            <NotificarePushManager scrollClass={scrollClass} />
            <Container>
                <div className={`navigation-bar`}>
                    <Link className={`brand`} to="/">
                        <img alt={siteTitle} src={logo} width="224" height="44" />
                    </Link>
                    <div className={`navigation`}>
                        {HeaderData[intl.locale].nav.map((item, i) => {
                            if (item.submenu) {
                                return (
                                    <div key={i} role="button" tabIndex={0} className={item.submenu.image ? `navigation-item with-image` : `navigation-item`} onMouseOver={onShowSubmenu} onFocus={onShowSubmenu}>
                                        <div className={`item has-children`}>{item.label} <FontAwesomeIcon icon="angle-down" /></div>
                                        <div className={`submenu`}>
                                            {item.submenu.image &&
                                            <div className={`image`}><ImageFluid alt="" filename={item.submenu.image} /></div>
                                            }
                                            {item.submenu.sections.map((section, j) => {
                                                return(
                                                    <div key={j} className={`section`}>
                                                        <div className={`title`}>{section.title}</div>
                                                        {section.shortcuts.map((shortcut, k) => {
                                                            if (shortcut.target) {
                                                                return (
                                                                    <a key={k} href={shortcut.url} className={`shortcut`} target={shortcut.target} rel={`noopener`}>
                                                                        {shortcut.label}
                                                                    </a>
                                                                )
                                                            } else {
                                                                return(
                                                                    <Link key={k} className={`shortcut`} to={shortcut.url}>
                                                                        {shortcut.label}
                                                                    </Link>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={i} role="button" tabIndex={0} className={`navigation-item`} onMouseOver={onHideAllSubMenus} onFocus={onHideAllSubMenus}>
                                        <Link className={`item`} to={item.url}>
                                            {item.label}
                                        </Link>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div className={`buttons`}>
                        {HeaderData[intl.locale].buttons.map((item, i) => {
                            if (item.target) {
                                return (
                                    <a key={i} href={item.url} className={`btn btn-lg btn-outline-light signin-button`} target={item.target} rel={`noopener`}>
                                        {item.label}
                                    </a>
                                )
                            } else {
                                return (
                                    <Link key={i} className={handleThemeForDemoButton(theme)} to={item.url}>
                                        {item.label}
                                    </Link>
                                )
                            }

                        })}
                    </div>
                    <a href={`/`} onClick={handleOpenMenu} className={`open-menu-button`}>
                        <div className={`lines line-1`}></div>
                        <div className={`lines line-2`}></div>
                        <div className={`lines line-3`}></div>
                    </a>
                    <a href={`/`} onClick={handleCloseMenu} aria-label="Close Menu" className={`close-menu-button`}><span></span></a>
                </div>
            </Container>
        </div>
    )

}

export default injectIntl(Header)



