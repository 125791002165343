import React from "react";
import {FormattedMessage, injectIntl} from "gatsby-plugin-react-intl";
import {Button, Container, Form} from "react-bootstrap";

class CookieManager extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            analytics: "G-3ZEY1ZKFDJ", //UA-32545615-1
            ads: "AW-970402719",
            showCookieManager: false,
            strict: true,
            functional: true,
            performance: true,
            marketing: true
        };
    }

    componentDidMount(){
        if (typeof window !== 'undefined' && !localStorage.getItem("notificareCookieManager")) {
            localStorage.setItem("notificareCookieManager", JSON.stringify({
                accepted: false,
                strict: true,
                functional: true,
                performance: true,
                marketing: true
            }));
        }

        let settings = JSON.parse(localStorage.getItem("notificareCookieManager"));
        this.setState({
            showCookieManager: !settings.accepted,
            strict: settings.strict,
            functional: settings.functional,
            performance: settings.performance,
            marketing: settings.marketing
        });

        window.dataLayer = window.dataLayer || [];
        this.pushToDataLayer('js', new Date());
        this.pushToDataLayer('config', this.state.analytics);
        this.pushToDataLayer('config', this.state.ads);
        this.handleDefaultConsent();

        if (!document.getElementById('GTAG')) {
            let s = document.createElement("script");
            s.id = "GTAG";
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://www.googletagmanager.com/gtag/js?id=" + this.state.analytics;
            let firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(s, firstScript);
            s.onload = () => {
                this.handleUpdateConsent();
            }
        } else {
            this.handleUpdateConsent();
        }
    }

    handleFunctionalSwitchChange = () => {
        this.setState({
            functional: !this.state.functional
        });
    }

    handlePerformanceSwitchChange = () => {
        this.setState({
            performance: !this.state.performance
        });
    }

    handleMarketingSwitchChange = () => {
        this.setState({
            marketing: !this.state.marketing
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        localStorage.setItem("notificareCookieManager", JSON.stringify({
            accepted: true,
            strict: true,
            functional: this.state.functional,
            performance: this.state.performance,
            marketing: this.state.marketing
        }));

        this.setState({
            showCookieManager: false
        });

        this.handleUpdateConsent();
    }

    showCookieManager = () => {
        this.setState({
            showCookieManager: true
        });
    }

    handleUpdateConsent(){
        let settings = JSON.parse(localStorage.getItem("notificareCookieManager"));
        if (settings?.accepted) {
            //TODO: Redact data when denied
            this.pushToDataLayer('consent', 'update', {
                'analytics_storage': this.state.performance ? 'granted' : 'denied',
                'ad_storage': this.state.marketing ? 'granted' : 'denied',
                'ad_user_data': this.state.marketing ? 'granted' : 'denied',
                'ad_personalization': this.state.marketing ? 'granted' : 'denied',
            });

            if (typeof window !== 'undefined' && window.location.pathname === "/forms/thank-you/" && this.state.marketing) {
                this.pushToDataLayer('event', 'conversion', {'send_to': this.state.ads + '/RtBMCIKxj10Qn9fczgM'});
            }
        }
    }

    handleDefaultConsent() {
        this.pushToDataLayer('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied',
            'wait_for_update': 1000,
        });
    }

    pushToDataLayer(){
        window.dataLayer.push(arguments);
    }

    render() {
        if (this.state.showCookieManager) {
            return (
                <div className={`cookie-manager open`}>
                    <Container>
                        <div className={`cookie-manager-box`}>
                            <div className={`title`}><FormattedMessage id="components.cookieManager.title" /></div>
                            <div className={`text`} dangerouslySetInnerHTML={{__html: this.props.intl.formatMessage({id: "components.cookieManager.text"})}}/>
                            <Form className={`form-area`} onSubmit={this.handleSubmit}>
                                <Form.Check
                                    disabled
                                    checked={this.state.strict}
                                    value={`strict`}
                                    type="switch"
                                    id="strict-switch"
                                    label={this.props.intl.formatMessage({id: "components.cookieManager.types.strict.label"})}
                                />
                                <Form.Check
                                    checked={this.state.functional}
                                    value={`functional`}
                                    type="switch"
                                    id="functional-switch"
                                    label={this.props.intl.formatMessage({id: "components.cookieManager.types.functional.label"})}
                                    onChange={this.handleFunctionalSwitchChange}
                                />
                                <Form.Check
                                    checked={this.state.performance}
                                    value={`performance`}
                                    type="switch"
                                    id="performance-switch"
                                    label={this.props.intl.formatMessage({id: "components.cookieManager.types.performance.label"})}
                                    onChange={this.handlePerformanceSwitchChange}
                                />
                                <Form.Check
                                    checked={this.state.marketing}
                                    value={`marketing`}
                                    type="switch"
                                    id="marketing-switch"
                                    label={this.props.intl.formatMessage({id: "components.cookieManager.types.marketing.label"})}
                                    onChange={this.handleMarketingSwitchChange}
                                />
                                <Button className={`button`} variant="primary" type="submit"><FormattedMessage id="components.cookieManager.button" /></Button>
                            </Form>
                        </div>
                    </Container>
                </div>
            )
        } else {
            return (
                <div className={`cookie-manager`}>
                    <div className={`cookie-settings`}>
                        <Button className={`button btn btn-outline-dark`} variant={`white`} onClick={this.showCookieManager}><FormattedMessage id="components.cookieManager.title" /></Button>
                    </div>
                </div>
            )
        }
    }
}

export default injectIntl(CookieManager);
