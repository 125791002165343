import React from "react";
import {FormattedMessage, injectIntl} from "gatsby-plugin-react-intl";

class StatusPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            lastState: false
        };
    }

    componentDidMount(){
        if (!document.getElementById('StatusPage')) {
            let s = document.createElement("script");
            s.id = "StatusPage";
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://cdn.statuspage.io/se-v2.js";
            document.body.appendChild(s);
            s.onload = () => {
                this.loadStatus();
            }
        } else {
            this.loadStatus();
        }

        //Handle changes to functional cookies settings
        this.intervalId = setInterval(() => {
            this.loadStatus();
        }, 1000);
    }

    loadStatus = () => {
        let settings = JSON.parse(localStorage.getItem("notificareCookieManager"));

        if (settings.functional !== this.state.lastState) {
            if (settings.functional) {
                if (window && window.StatusPage && window.StatusPage.page) {
                    let sp = new window.StatusPage.page({ page: '22shpstgncrg' });
                    sp.summary({ success : (data) => {
                            this.setState({
                                status: data.status.indicator,
                                description: data.status.description
                            });
                        }
                    });
                } else {
                    this.setState({
                        status: null,
                        description: null
                    });
                }
            } else {
                this.setState({
                    status: null,
                    description: null
                });
            }
        }

        this.setState({
            lastState: settings.functional
        });
    }

    componentWillUnmount(){
        clearInterval(this.intervalId);
    }

    render() {
        if (this.state && this.state.status) {
            return (
              <div className={`platform`}>
                  <a className={`status-page-link`} href={`https://notificare.statuspage.io`} target={`_blank`} rel={`noopener`}>
                      <span className={`title`}><FormattedMessage id="components.footer.platform" /></span>
                      <span className={`status-icon ` + this.state.status}></span>
                      <span className={`status`}>{this.state.description}</span>
                  </a>
              </div>
            )
        } else {
            return null;
        }
    }
}

export default injectIntl(StatusPage);
